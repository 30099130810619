import React from 'react';
import { Link } from 'react-router-dom';

const FooterForHome = () => {
  return (
    <footer className="  bg-light py-3 border-top rounded-4">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="text-muted">
          &copy; 2024 Dorean. All rights reserved.
        </div>
        <div>
          <Link to="/PricayPolicy" className="text-decoration-none text-muted">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default FooterForHome;
