import React from "react";
import ContactUsNavbar from "../components/ContactUsNavbar";
import AboutNavbar from "../components/AboutNavbar";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="bg-dark">
        <AboutNavbar />
      </div>
      
      <div className="container py-5">
        <h2 className="mb-4">Privacy Policy for Dorean</h2>
        <p>
          At <strong>Dorean</strong>, your privacy is of utmost importance to us. This Privacy Policy
          explains what information we collect, how we use it, and how we ensure it is protected.
          By using our website, you agree to the collection and use of information in accordance
          with this policy.
        </p>
        
        <h4>Information We Collect</h4>
        <p>
          We do not collect personal information unless you voluntarily provide it to us.
          Specifically, we may collect the following information when you choose to contact us via
          our "Contact Us" form:
        </p>
        <ul>
          <li>
            <strong>Email Address:</strong> Used solely for responding to inquiries or requests made
            through the "Contact Us" form.
          </li>
          <li>
            <strong>Phone Number:</strong> Collected if provided and used only to communicate with
            you regarding your inquiry.
          </li>
        </ul>
        
        <h4>How We Use Your Information</h4>
        <p>
          The information you provide will only be used to:
        </p>
        <ul>
          <li>Respond to your inquiries.</li>
          <li>Provide customer support, if necessary.</li>
        </ul>
        <p>
          We do <strong>not</strong> sell, trade, or share your personal information with any third
          parties. Your contact details are kept confidential and used exclusively for the purposes
          of communication as outlined above.
        </p>

        <h4>Google Ads and Cookies</h4>
        <p>
          Our website uses Google Ads to display advertisements. Google, as a third-party vendor,
          uses cookies to serve ads based on your visits to our website and other websites. Cookies
          are small text files stored on your device to help improve your browsing experience and
          deliver personalized ads.
        </p>
        <ul>
          <li>
            <strong>Google and third-party vendors use cookies</strong> to serve ads based on prior
            visits to our website and other sites across the web.
          </li>
          <li>
            <strong>Google's use of advertising cookies</strong> allows it to serve ads to you based
            on your visit to Dorean and other sites you may visit.
          </li>
        </ul>
        <p>
          You may opt-out of personalized advertising by visiting{" "}
          <a href="https://adssettings.google.com" target="_blank" rel="noopener noreferrer">
            Google’s Ads Settings
          </a>{" "}
          or by visiting{" "}
          <a href="https://www.aboutads.info/" target="_blank" rel="noopener noreferrer">
            www.aboutads.info
          </a>{" "}
          to opt-out of third-party vendors’ use of cookies for personalized ads.
        </p>

        <h4>Data Protection</h4>
        <p>
          We take appropriate measures to protect your information against unauthorized access,
          alteration, disclosure, or destruction. However, no method of transmission over the
          Internet is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h4>No Collection of Sensitive Personal Information</h4>
        <p>
          We do not collect any sensitive personal information, such as financial information,
          Social Security numbers, or personal identification numbers. Any communication via our
          "Contact Us" form is strictly for responding to inquiries.
        </p>

        <h4>Your Consent</h4>
        <p>
          By using Dorean's website, you consent to the collection and use of your information as
          described in this Privacy Policy. If there are any changes to our privacy practices, we
          will update this page to reflect those changes.
        </p>

        <h4>Contact Us</h4>
        <p>
          If you have any questions or concerns about this Privacy Policy, please reach out to us
          via our "Contact Us" page or by emailing us at <strong>10023946@gmail.com</strong>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
