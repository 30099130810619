import React from 'react'
import App from './App'
import "./App.css";
import Navbar from './components/Navbar'
import { Route, Routes } from 'react-router-dom'
import Abouts from './screens/Abouts'
import ContatUs from './screens/ContatUs'
import PrivacyPolicy from './screens/PrivacyPolicy';

const SecondApp = () => {
  return (
    <Routes> 
      <Route path='/' element={<App/>}/>
      <Route path='/aboubts' element={<Abouts/>}/>
      <Route path='/contact' element={<ContatUs/>}/>
      <Route path='/PricayPolicy' element={<PrivacyPolicy/>}/>
    </Routes>
  )
}

export default SecondApp
